import { IAuthEvent } from '@features/auth/core/domain/events/auth.event';
import { SESSION_STORAGE_KEYS } from '../../const';
export class StorageHelper {
  public static STORAGE_KEY = SESSION_STORAGE_KEYS;

  public static storeCallbackURL(redirect: string) {
    // Case: '/feature/dashboard' -> 'feature/dashboard'
    if (redirect.startsWith('/')) {
      redirect = redirect.substring(1);
    }
    sessionStorage.setItem(StorageHelper.STORAGE_KEY.CALLBACK_URL, redirect);
  }

  public static getCallBackURL() {
    return sessionStorage.getItem(StorageHelper.STORAGE_KEY.CALLBACK_URL) ?? '';
  }

  public static storeAuthRequire(req: any | undefined) {
    sessionStorage.setItem(
      StorageHelper.STORAGE_KEY.CURRENT_REQUIREMENT_AFTER_LOGIN,
      req ? JSON.stringify(req) : ''
    );
  }

  public static getAuthRequire() {
    return (
      sessionStorage.getItem(
        StorageHelper.STORAGE_KEY.CURRENT_REQUIREMENT_AFTER_LOGIN
      ) ?? ''
    );
  }

  public static storeLoginCountError(count: number) {
    sessionStorage.setItem(
      StorageHelper.STORAGE_KEY.LOGIN_ERROR_RECAPTCHA_COUNT,
      count.toString()
    );
  }
  public static getLoginCountError() {
    return (
      sessionStorage.getItem(
        StorageHelper.STORAGE_KEY.LOGIN_ERROR_RECAPTCHA_COUNT
      ) ?? 0
    );
  }

  public static setAuthInfo(
    token: string,
    authInfo: any | undefined,
    activatedAction: string[] | number[]
  ) {
    localStorage.setItem(StorageHelper.STORAGE_KEY.ACCESS_TOKEN, token);
    localStorage.setItem(
      StorageHelper.STORAGE_KEY.USER_INFORMATION,
      JSON.stringify(authInfo)
    );
    localStorage.setItem(
      StorageHelper.STORAGE_KEY.LIST_ACTION_ACTIVE,
      JSON.stringify(activatedAction)
    );
  }

  public static emptySessionAndLocalStorage() {
    localStorage.removeItem(StorageHelper.STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(StorageHelper.STORAGE_KEY.USER_INFORMATION);
    localStorage.removeItem(StorageHelper.STORAGE_KEY.LIST_ACTION_ACTIVE);
    sessionStorage.clear();
  }

  public static setAccessToken(token: string) {
    localStorage.setItem(StorageHelper.STORAGE_KEY.ACCESS_TOKEN, token);
  }
  public static getAccessToken() {
    return localStorage.getItem(StorageHelper.STORAGE_KEY.ACCESS_TOKEN) ?? '';
  }

  public static getUserInfo() {
    const info = localStorage.getItem(
      StorageHelper.STORAGE_KEY.USER_INFORMATION
    );
    if (info) {
      return JSON.parse(info);
    }
    return null;
  }

  public static setUserInfo(authInfo: any) {
    localStorage.setItem(
      StorageHelper.STORAGE_KEY.USER_INFORMATION,
      JSON.stringify(authInfo)
    );
  }

  public static storeRequireUpdateInfoToUseApp(info: any) {
    sessionStorage.setItem(
      StorageHelper.STORAGE_KEY.REQUIRE_UPDATE_MORE_INFO_AFTER_ENTER_APP,
      JSON.stringify(info)
    );
  }

  public static getRequireUpdateInfoToUseApp() {
    const info = sessionStorage.getItem(
      StorageHelper.STORAGE_KEY.REQUIRE_UPDATE_MORE_INFO_AFTER_ENTER_APP
    );
    if (info) {
      return JSON.parse(info);
    }
    return [];
  }

  public static getActivatedAction() {
    const info = localStorage.getItem(
      StorageHelper.STORAGE_KEY.LIST_ACTION_ACTIVE
    );
    if (info) {
      return JSON.parse(info);
    }
    return [];
  }

  public static getCurrentAuthEvent() {
    const info = localStorage.getItem(StorageHelper.STORAGE_KEY.AUTH_EVENT);
    if (info) {
      return JSON.parse(info);
    }
    return null;
  }
  public static setCurrentAuthEvent(event: IAuthEvent) {
    localStorage.setItem(
      StorageHelper.STORAGE_KEY.AUTH_EVENT,
      JSON.stringify(event)
    );
  }

  public static setAppKey(key: string) {
    localStorage.setItem(StorageHelper.STORAGE_KEY.APP_KEY, key);
  }

  public static getAppKey() {
    return localStorage.getItem(StorageHelper.STORAGE_KEY.APP_KEY);
  }

  public static setAccountFirstLoginHaveAVerifyLogic(bool: boolean) {
    sessionStorage.setItem(
      StorageHelper.STORAGE_KEY.HAVE_A_VERIFY_LOGIC,
      bool ? 'yes' : 'no'
    );
  }

  public static getAccountFirstLoginHaveAVerifyLogic() {
    return sessionStorage.getItem(
      StorageHelper.STORAGE_KEY.HAVE_A_VERIFY_LOGIC
    ) == 'yes'
      ? true
      : false;
  }

  public static removeAccountFirstLoginHaveAVerifyLogic() {
    sessionStorage.removeItem(StorageHelper.STORAGE_KEY.HAVE_A_VERIFY_LOGIC);
  }
}
